<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-11-15 14:04:33
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2025-01-07 14:19:27
 * @Description: 
-->
<template>
	<div id="page">
		<!--<a-breadcrumb style="margin: 16px 0 8px">
      <a-breadcrumb-item v-for="(item, index) in matchedArr" :key="item.name">
        <router-link :to="item.path"
          >{{ index === matchedArr.length - 1 ? "当前页面" : item.meta.title }}
        </router-link>
      </a-breadcrumb-item>
    </a-breadcrumb>-->
		<div class="nowPage">
			<!-- {{ matchedArr[matchedArr.length - 1].meta.title }} -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'Breadcrumb',
	data() {
		return {}
	},
	computed: {
		matchedArr() {
			return this.$route.matched.filter(item => item.name)
		}
	}
}
</script>

<style scoped lang="less">
.nowPage {
	height: 20px;
	// margin: 16px 0;
	// min-height: 28px;
	// font-size: 20px;
	// font-weight: bold;
	// color: rgba(0, 0, 0, 0.85);
	// line-height: 28px;
}
</style>
