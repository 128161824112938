<template>
	<div>
		<a-layout id="components-layout-demo-top-side-2">
			<a-affix :offset-top="0" :style="{zIndex: '99999'}">
				<a-layout-header class="header">
					<div class="logo">
						<img class="cursor-pointer" src="@/assets/logo.png" alt="logo" @click="logoTap" />
						<!--<span>订个包间</span>-->
					</div>
					<Header />
				</a-layout-header>
			</a-affix>
			<a-layout>
				<a-affix :offset-top="65" :style="{zIndex: '0', minWidth: '200px', flexShrink: '0'}">
					<!--<a-layout-sider
            width="200"
            class="side-menu"
            style="background: #fff"
          >
            <side-menu></side-menu>
          </a-layout-sider>-->
				</a-affix>
				<a-layout
					:style="{
						padding: $route.meta.hideTitle ? '' : '0 24px 24px',
						overflow: 'hidden'
					}">
					<breadcrumb v-if="!$route.meta.hideTitle"></breadcrumb>
					<a-spin size="large" :spinning="$store.state.loading.contentLoading">
						<a-layout-content
							class="user-layout-content"
							:style="{
								background: '#fff',
								borderRadius: '4px'
							}">
							<router-view />
						</a-layout-content>
					</a-spin>
				</a-layout>
			</a-layout>
		</a-layout>
	</div>
</template>

<script>
import Header from '@/layouts/Header'
import Breadcrumb from '@/layouts/Breadcrumb'
import {clearPassword} from '@/api/dashboard'

export default {
	name: 'BasicLayout',
	components: {Breadcrumb, Header},
	data() {
		return {
			collapsed: false
		}
	},
	methods: {
		logoTap() {
			if (!this.$store.state.menu.isInDashbord) {
				clearPassword().then(res => {
					if (res.code === 0) {
						this.$router.push({name: 'Index'})
					}
				})
			}
		}
	},
	watch: {}
}
</script>

<style scoped lang="less">
#components-layout-demo-top-side-2 .logo {
	img {
		width: 148px;
		height: auto;
		margin-right: 7px;
	}

	span {
		font-size: 24px;
		color: @primary-color;
		font-weight: bold;
		line-height: 1.5;
	}

	width: 175px;
	height: 100%;
	display: flex;
	user-select: none;
	align-items: center;
	float: left;
}

.header {
	height: 65px;
	background: #ffffff;
	box-shadow: 0px 2px 6px 0px rgba(42, 42, 42, 0.1);
	z-index: 1;
	position: relative;
}

.side-menu {
	height: calc(100vh - 65px);
	min-height: calc(100vh - 65px);
	max-height: calc(100vh - 65px);
	width: 200px;
	min-width: 200px;
	border-right: 0;
	z-index: 1;
	overflow-y: auto;
}

.user-layout-content {
	margin: 0;
	min-height: 280px;
	//min-height: calc(100vh - 150px);
	flex: 1;
}

::v-deep .ant-menu-item > a {
	font-weight: bold;
}
</style>
